// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artigos-js": () => import("/opt/build/repo/src/pages/artigos.js" /* webpackChunkName: "component---src-pages-artigos-js" */),
  "component---src-pages-contato-js": () => import("/opt/build/repo/src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-exames-js": () => import("/opt/build/repo/src/pages/exames.js" /* webpackChunkName: "component---src-pages-exames-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sobre-js": () => import("/opt/build/repo/src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-tratamentos-js": () => import("/opt/build/repo/src/pages/tratamentos.js" /* webpackChunkName: "component---src-pages-tratamentos-js" */)
}

